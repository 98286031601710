import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from 'twin.macro'

import {Layout} from '@components/layout/layout'
import { H1, MainContent } from "@components/layout/style"

const Grid = styled('div')`
${tw`grid grid-flow-col lg:grid-cols-3 grid-cols-1 place-items-center auto-rows-auto grid-flow-row gap-8 leading-normal mt-6`}
`

const Person = styled('div')`
${tw`
mb-6
w-72
h-52
rounded
shadow-lg
bg-gray-200
text-center
p-8
flex
flex-col
justify-center
gap-4`}
`

const Avatar = styled('div')`
${tw`opacity-80 rounded-full m-auto`}
img {
  ${tw`opacity-80 rounded-full`}
}
`

const zespol = [
  {name:'lek. dent. Joanna Dąbrowicz'},
  {name:'lek. dent. Katarzyna Adamczak'},
  {name:'lek. dent. Agnieszka Misztak'},
  {name:'lek. dent. Maurycy Kutuć'},
  {name:'lek. dent. Piotr Kozicki'},
  {name:'dr n. med. lek. dent. Elżbieta Smolak'},
  {name:'lek. dent. Sylwia Czajkowska'},
  {name:'lek. dent. Debhora Nowacka'},
  {name:'lek. dent. Joanna Pawłowska'},
  {name:'lek. dent. Urszula Korzeniowska'},
]

const PersonAvatar:React.FC = ({prop}) => {
  return (
    <Avatar>
      <StaticImage
        src="../assets/images/pe1.jpg"
        layout="fixed"
        width={150}
        height={150}
        placeholder="blurred"
        alt={prop?.alt}
        imgClassName="opacity-80"/>
    </Avatar>)
}



const TeamTemplate:React.FC = ({data}) => {
  return (
  <Layout
    data={data}
    prop={{
      canonicalUrl: 'https://www.mediroot.pl/zespol/',
      title: 'Zespół | Mediroot',
      description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
    }}>
    <MainContent>
      <H1 style={{'text-align': 'center'}}>Nasz zespół</H1>
      <Grid>
        {zespol.map((z) =>{
          return (
          <Person>
            <PersonAvatar prop={ {alt: `Mediroot – ${ z.name }`}}>
            </PersonAvatar>
            {z.role} {z.name}
          </Person>)
        })
        }
{/*
        <Person>
            <PersonAvatar>
            </PersonAvatar>
          <PersonDetails>
            <PersonName> lek. dent. Joanna Dąbrowicz</PersonName>
            <PersonBio>
              <p>
                Absolwentka Uniwersytetu Medycznego w Łodzi.
              </p>

                <p>
                  Specjalizacja: stomatologia dziecięca (pedodoncja), endodoncja konwencjonalna (leczenie kanałowe oraz re-endo), stomatologia estetyczna i zachowawcza, protetyka.
                </p>

    <p>
    Systematycznie uczestniczy w kursach i szkoleniach aby zagwarantować swoim pacjentom jak najwyższą jakość usług.W pracy zawodowej kieruje się jakością i profesjonalizmem. W trakcie leczenie pacjenta zwraca szczególną uwagę aby zarówno aspekt zdrowotny jak i estetyczny stał na najwyższym poziomie.

    </p>

    <p>
    Skupia się szczegolnie na holistycznym podejsciu do zdrowia dzieci. Zwraca uwagę na prawidłowa postawę dzieci, ponieważ często obok wady zgryzu wśród dzieci i młodzieży pojawia się problem nieprawidłowej postawy, która jeszcze w tym młodym wieku jest możliwa do skorygowania przez ortopedów.

    </p>
            </PersonBio>
          </PersonDetails>
        </Person>


        <PersonRight>
            <PersonAvatar>
            </PersonAvatar>
          <PersonDetails>
            <PersonName> lek. dent. Katarzyna Migas</PersonName>
            <PersonBio>
              <p>
              Absolwentka kierunku lekarsko-dentystycznego Uniwersytetu Medycznego im. Karola Marcinkowskiego w Poznaniu.
              </p>

                <p>
                Zajmuje się przede wszystkim leczeniem zachowawczym zębów, profilaktyką chorób jamy ustnej, a także leczeniem endodontycznym zębów. Jest zwolenniczką holistycznego podejścia do leczenia pacjentów oraz ścisłej, interdyscyplinarnej współpracy specjalistów z dziedziny stomatologii
                Systematycznie pogłębia swoją wiedzę teoretyczną i praktyczną biorąc udział w licznych szkoleniach, kursach oraz konferencjach naukowych,   aby zagwarantować swoim pacjentom jak najwyższą jakość usług.
                </p>
            </PersonBio>
          </PersonDetails>
        </PersonRight>
        <Person>
            <PersonAvatar>
            </PersonAvatar>
          <PersonDetails>
            <PersonName> lek. dent. Agnieszka Misztak</PersonName>
            <PersonBio>
              <p>
              Ukończyła kierunek lekarsko-dentystyczny na Uniwersytecie Medycznym w Poznaniu.
              </p>

                <p>
                Wykonuje zabiegi z zakresu chirurgii stomatologicznej ,stomatologii zachowawczej, endodoncji i protetyki. Najważniejszy w pracy zawodowej jest dla niej dobry kontakt z pacjentem, co daje możliwość kompleksowego, interdyscyplinarnego leczenia, prowadzącego nie tylko do pięknego uśmiechu, ale i poprawy ogólnego stanu zdrowia pacjenta.
                </p>

                <p>
                Poświęca sporo czasu stomatologii dziecięcej, w razie potrzeby wykonując zabiegi z wykorzystaniem podtlenku azotu. Stomatologia jest jej pasją. Nieustannie podnosi swoje umiejętności na zaawansowanych szkoleniach dla lekarzy stomatologów.
                </p>

    <p>
    Skupia się szczegolnie na holistycznym podejsciu do zdrowia dzieci. Zwraca uwagę na prawidłowa postawę dzieci, ponieważ często obok wady zgryzu wśród dzieci i młodzieży pojawia się problem nieprawidłowej postawy, która jeszcze w tym młodym wieku jest możliwa do skorygowania przez ortopedów.

    </p>
            </PersonBio>
          </PersonDetails>
        </Person>

        <PersonRight>
            <PersonAvatar>
            </PersonAvatar>
          <PersonDetails>
            <PersonName> lek. dent. Maurycy Kutuć</PersonName>
            <PersonBio>
              <p>
              Absolwent Poznańskiej Akademii Medycznej na wydziale lekarsko-dentystycznym.
              </p>

                <p>
                Perfekcjonista i esteta. Z każdego uśmiechu potrafi zrobić małe dzieło sztuki. Jego największą satysfakcją jest zadowolenie pacjentów.
                </p>
                <p>
                Stale stara się poszerzać swoją wiedzę zawodową, uczestnicząc w licznych kursach i szkoleniach.
    W pracy kieruje się dobrem pacjenta, dokładną diagnostyką a przede wszystkim kompleksowym leczeniem stomatologicznym na najwyższym poziomie. Jego największa pasja to chirurgia stomatologiczna , zajmuje się również stomatologią zachowawczą oraz leczeniem naszych malutkich pacjentów.

                </p>
            </PersonBio>
          </PersonDetails>
        </PersonRight>

        <Person>
            <PersonAvatar>
            </PersonAvatar>
          <PersonDetails>
            <PersonName> lek. dent. Aleksandra Sroka</PersonName>
            <PersonBio>
              <p>
              Absolwentka Uniwersytetu Medycznego w Łodzi.
              </p>

                <p>Ortodoncja jest specjalnością, której oddaje się bez reszty i to rozwój w tej dziedzinie motywuje ją do poszerzania wiedzy oraz umiejętności zdobywanych na licznych
    kongresach i kursach ortodontycznych. Żadna inna dziedzina stomatologii nie sprawia jej tyle radości i osobistej satysfakcji.
                </p>

                <p>Jest sympatykiem holistycznego podejścia do leczenia pacjentów oraz ścisłej, interdyscyplinarnej współpracy specjalistów z dziedziny stomatologii i medycyny estetycznej.
    W ortodoncji liczy się dla niej  nie tylko kreacja estetyki uśmiechu, ale również estetyki twarzy.
                </p>
            </PersonBio>
          </PersonDetails>
        </Person>

        <PersonRight>
            <PersonAvatar>
            </PersonAvatar>
          <PersonDetails>
            <PersonName> lek. dent. Urszula Korzeniowska</PersonName>
            <PersonBio>
              <p>
              Absolwentka Uniwersytetu Medycznego w Poznaniu.
              </p>

                <p>
                Stomatologia jest jej pasją.
    Specjalizuje  się w chirurgii stomatologicznje i implantologii. W zakresie jej zainteresowań znajduje się również szeroko pojęta protetyka, stomatologia zachowawcza i estetyczna.  Ceni sobie indywidualne podejscie do każdego pacjenta.Stale monitoruje najnowsze trendy i osiągnięcia w stomatologii zachowawczej, rozwija swoje umiejętności i kwalifikacje zawodowe uczestnicząc w licznych kursach i kongresach . Patrzy na pacjenta i jego problemy całościowo, wykorzystując swoje doświadczenie przy tworzeniu kompleksowych planów leczenia.
                </p>
            </PersonBio>
          </PersonDetails>
        </PersonRight> */}

      </Grid>
      </MainContent>

  </Layout>);
};

export default TeamTemplate;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
